import "autotrack/lib/plugins/clean-url-tracker";
import "autotrack/lib/plugins/event-tracker";
import "autotrack/lib/plugins/impression-tracker";
import "autotrack/lib/plugins/max-scroll-tracker";
import "autotrack/lib/plugins/media-query-tracker";
import "autotrack/lib/plugins/outbound-link-tracker";
import "autotrack/lib/plugins/page-visibility-tracker";

window.addEventListener("load", () => {
  ga("create", "UA-96178330-1", "auto");

  ga("set", "anonymizeIp", true);

  ga("require", "cleanUrlTracker", {
    trailingSlash: "remove"
  });
  ga("require", "eventTracker");

  let elements_to_track = getAdUnitIds();

  ga("require", "impressionTracker", {
    elements: elements_to_track
  });
  ga("require", "maxScrollTracker", {
    hitFilter: function(model) {
      var scrollPercentage = model.get("eventLabel");
      if (scrollPercentage > 50) {
        model.set("nonInteraction", true, true);
      }
    }
  });
  ga("require", "mediaQueryTracker", {
    definitions: [
      {
        name: "Breakpoint",
        dimensionIndex: 1,
        items: [
          { name: "sm", media: "all" },
          { name: "md", media: "(min-width: 1024px)" },
          { name: "lg", media: "(min-width: 1280px)" }
        ]
      },
      {
        name: "Orientation",
        dimensionIndex: 2,
        items: [
          { name: "landscape", media: "(orientation: landscape)" },
          { name: "portrait", media: "(orientation: portrait)" }
        ]
      }
    ]
  });
  ga("require", "outboundLinkTracker");
  ga("require", "pageVisibilityTracker");

  let dimensions = {};

  try {
    dimensions = JSON.parse(
      document.getElementById("dimensions-data").textContent
    );
  } catch (error) {}

  ga("send", "pageview", dimensions);
});

function getAdUnitIds() {
  let ad_units = document.querySelectorAll('*[id^="ad-unit-"]'),
    ad_unit_ids = [];

  for (var i = ad_units.length - 1; i >= 0; i--) {
    ad_unit_ids.push(ad_units[i].getAttribute("id"));
  }

  return ad_unit_ids;
}
